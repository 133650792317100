/* eslint-disable no-console */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import useStyles from './articles-style'
import ArticleCard from './article-card'
import {
  Layout, List, Pagination, Breadcrumb, Title, Loading, SEO,
} from '../../ui'

function ArticlesView({
  // the page will be ready after loading article data
  pageReady = true,
  tags = [],
  slug,
  categories,
  articles = [],
  breadcrumb,
  pagination,
  seoTitle,
  onPageClick,
}) {
  const { t } = useTranslation()
  const styles = useStyles()
  const handlePageClick = (e) => {
    if (e.selected >= 0) onPageClick(e.selected + 1)
  }
  return (
    <Layout>
      {/* passing page specific data for SEO */}
      {/* TODO: add seo title */}
      <SEO
        title={seoTitle}
        linkedData={_.isEmpty(articles) ? [] : [{
          '@context': 'http://schema.org',
          '@type': 'ItemList',
          itemListElement: articles.map((article) => ({
            '@type': 'Article',
            headline: article.title,
            image: _.get(article, 'defaultImage.versions.ecomMedium'),
            description: article.excerpt,
            datePublished: moment(_.get(article, 'publishStartDate')).format('YYYY-MM-DD'),
            author: [{
              '@type': 'Organization',
              name: article.author,
            }],
          })),
        }]}
      />

      {pageReady ? (
        <>
          {
            slug && (
            <Breadcrumb
              links={breadcrumb}
              classN={styles.breadcrumb}
            />
            )
          }
          <div className={styles.containerFluid}>
            {slug && <Title text={slug} />}
            <div className={styles.container}>
              <article>
                {articles.map((article) => (
                  <ArticleCard
                    key={article.id}
                    article={article}
                  />
                ))}
                <div className={styles.pagination}>
                  {
                    pagination.totalPages > 1
                    && <Pagination pagination={pagination} handlePageClick={handlePageClick} />
                  }
                </div>
              </article>
              <aside>
                {
                  categories.length > 0 && (
                    <div>
                      <List
                        title={t('screens.articles.categoryList')}
                        type="category"
                        url="/articles/category"
                        listItems={categories}
                      />
                    </div>
                  )
                }
                {
                  tags.length > 0 && (
                    <div>
                      <List
                        title={t('screens.articles.tagList')}
                        type="tag"
                        listItems={tags}
                        url="/articles/tag"
                      />
                    </div>
                  )
                }
              </aside>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </Layout>
  )
}

export default ArticlesView
